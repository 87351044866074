<template>
	<b-modal
	title="Salon en vivo"
	hide-footer 
	size="lg"
	id="salon-mapa-en-vivo">
		<salon
		modo="mapa-en-vivo"
		:salon="salon"></salon>
	</b-modal>	
</template>
<script>
import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	components: {
		Salon: () => import('@/components/salon/Salon'),
	},
	computed: {
		salones() {
			return this.$store.state.salon.models 
		},
		salon() {
			return this.salones[0]
		},
	},
}
</script>